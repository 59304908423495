<template>
    <div class="container">
        <div class="banner">
            <img :src="info.banner">
        </div>
        <div class="brand">
            <div class="navtitle">
                <div class="title">品牌简介</div>
                <div class="horizontal_line"></div>
            </div>
            <p class="brand_intro">{{ info.intro }}</p>
            <div class="logo_list">
                <div class="logo_list_item" v-for="(item,index) in info.logos" :key="index">
                    <img v-lazy="item">
                </div>
            </div>
        </div>
        <div class="advantage">
            <div class="navtitle">
                <div class="title" style="color: #fff">核心优势</div>
                <div class="horizontal_line" style="background: rgba($color: #fff, $alpha: 0.3);"></div>
            </div>
            <div class="adv_list">
                <div class="adv_list_item" v-for="(item,index) in info.adv" :key="index">
                    <img :src="item.icon">
                    <div class="title">{{ item.title }}</div>
                    <div class="line"></div>
                    <div class="intro">{{ item.intro }}</div>
                </div>
            </div>
        </div>
        <div class="introduce">
            <div class="navtitle">
                <div class="title">项目介绍</div>
                <div class="horizontal_line"></div>
            </div>
            <div class="header">
                <div class="title">{{ info?.projects[current - 1]?.title }}</div>
                <div class="change">
                    <div class="btn_l" @click="chagneProject('left')">
                        <img src="../../../assets/image/zh.png">
                    </div>
                    <div class="center">
                        {{ current }} / {{ info?.projects?.length }}
                    </div>
                    <div class="btn_r" @click="chagneProject('right')">
                        <img src="../../../assets/image/yh.png">
                    </div>
                </div>
            </div>
            <div class="cooperate">合作方式：{{ info?.projects[current - 1]?.mode }}</div>
            <p>
                {{ info?.projects[current - 1]?.intro }}
            </p>
            <div class="number">
                <div>房源数：<span>{{ info?.projects[current - 1]?.housings_num }}套</span></div>
                <div>出租率：<span>{{ info?.projects[current - 1]?.rental_rate }}%</span></div>
                <div>租率同比提高：<span>{{ info?.projects[current - 1]?.add_rental_rate }}%</span></div>
            </div>
            <div class="img_list">
                <div class="img_list_item" v-for="(item,index) in info?.projects[current - 1]?.images" :key="index">
                    <img v-lazy="item.src">
                </div>
            </div>
        </div>
        <div class="intention">
            <div class="navtitle">
                <div class="title">填写合作意向</div>
                <div class="horizontal_line"></div>
            </div>
            <div class="subtitle">选择合作事项</div>
            <div class="select_list">
                <div class="select_list_item" :class="item.active?'active':''" v-for="(item,index) in selectList" :key="index" @click="changeSelect(index)">{{ item.label }}</div>
            </div>
            <div class="form">
                <span class="tip">填写前请选择恰当的合作分类</span>
                <input type="text" placeholder="请填写您所在的城市" v-model="formData.city">
                <input type="text" placeholder="请填写您的姓名" v-model="formData.true_name">
                <input type="text" placeholder="请填写您的邮箱" v-model="formData.email">
                <input type="text" placeholder="请填写您的手机号" v-model="formData.phone">
                <textarea rows="6" cols="6" placeholder="请填写您的合作内容" v-model="formData.content"></textarea>
                <div class="btn" @click="submitForm">
                    提交
                </div>
                <span class="phone">业务咨询热线：{{ info.business_hotline }}</span>
            </div>
        </div>
        <mobile-Loading v-if="isLoading"/>
    </div>
</template>
<script>
import { getData,addIntention } from '@/api/cooperation'
export default {
    data() {
        return {
            selectList: [],
            info: {
                projects: []
            },
            formData: {
                city: '', // 所在城市
                true_name: '', //姓名
                email: '', // 邮箱
                phone: '', // 手机号
                content: '', // 合作内容
                coop_items: [] // 合作事项
            },
            current: 1,
            isLoading: true
        }
    },
    mounted() {
        this.initData()
    },
    methods: {
        async initData() {
            try {
                const { data:res } = await getData()
                this.selectList = res.coops.map(item => {
                    return {
                        label: item,
                        value: item,
                        active: false
                    }
                })
                this.info = res
            }finally {
                this.isLoading = false
            }
        },
        changeSelect(i) {
            this.selectList[i].active = !this.selectList[i].active
        },
        chagneProject(type) {
            if(type == 'left') {
                if(this.current <= 1) return;
                this.current = this.current - 1
            }else if(type == 'right') {
                if(this.current >= this.info?.projects?.length) return;
                this.current = this.current + 1
            }
        },
        async submitForm() {
            this.selectList.filter(item => {
                return item.active
            }).forEach(item => {
                this.formData.coop_items.push(item.value)
            })

            for (const key in this.formData) {
                if(!this.formData[key] || !this.formData[key]?.length) {
                    let list = [
                        { key: 'city',name: '请填写城市'},
                        { key: 'true_name',name: '请填写姓名'},
                        { key: 'email',name: '请填写邮箱'},
                        { key: 'phone',name: '请填写手机号'},
                        { key: 'content',name: '请填写合作内容'},
                        { key: 'coop_items',name: '请选择合作事项'},
                    ]
                    let selectedList = list.filter(item => {
                        return item.key == key
                    })
                    this.$toast(`${selectedList[0].name}`)
                    return
                }
            }
            const res = await addIntention(this.formData)
            if(res.code == 200) {
                this.$toast('提交成功')
                this.formData = this.$options.data().formData
                this.selectList.forEach(item => {
                    item.active = false
                })
            }
        }
    }
}
</script>
<style scoped lang="scss">
.navtitle {
    .title {
        color: #FFA71D;
        font-size: 36px;
        font-weight: bold;
        width: 100%;
        text-align: center;
    }
    .horizontal_line {
        width: 150px;
        height: 2px;
        background-color: #EEEEEE;
        position: relative;
        margin: 24px auto;
        &::after {
            position: absolute;
            content: '';
            width: 40px;
            height: 4px;
            background-color: #FFA71D;
            left: 50%;
            top: -1px;
            transform: translateX(-50%);
        }
    }
}
.container {
    .banner {
        img {
            width: 100%;
            height: 400px;
            object-fit: cover;
        }
    }
    .brand {
        padding: 50px 30px;
        box-sizing: border-box;
        .brand_intro {
            font-size: 28px;
            color: #666;
            text-indent: 2em;
            margin-top: 30px;
        }
        .logo_list {
            display: flex;
            column-gap: 20px;
            row-gap: 20px;
            flex-wrap: wrap;
            margin-top: 40px;
            justify-content: center;
            &_item {
                width: 216px;
                flex-shrink: 0;
                img {
                    width: 100%;
                    height: 160px;
                    object-fit: cover;
                }
            }
        }
    }
    .advantage {
        padding: 50px 30px;
        box-sizing: border-box;
        width: 100%;
        height: 890px;
        background: url('../../../assets/image/m_hxys.png') 100% 100% no-repeat;
        background-position: center;
        background-size: cover;
        .adv_list {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 18px;
            margin-top: 20px;
            &_item {
                background: #fff;
                border-radius: 6px;
                flex: 1;
                height: 330px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                row-gap: 16px;
                img {
                    width: 112px;
                    height: 112px;
                    border-radius: 50%;
                }
                .title {
                    font-size: 28px;
                    color: #333333;
                    font-weight: bold;
                }
                .line {
                    width: 30px;
                    height: 4px;
                    background: #FFA71D;
                }
                .intro {
                    color: #666666;
                    font-size: 24px;
                }
            }
        }
    }
    .introduce {
        padding: 50px 30px;
        box-sizing: border-box;
        color: #666;
        font-size: 28px;
        .header {
            display: flex;
            justify-content: space-between;
            margin-top: 20px;
            .title {
                font-size: 32px;
                color: #333333;
                font-weight: bold;
                position: relative;
                padding-left: 20px;
                box-sizing: border-box;
                &::before {
                    content: '';
                    width: 6px;
                    height: 30px;
                    background: #FFA71D;
                    position: absolute;
                    top: 50%;
                    left: 0px;
                    transform: translateY(-50%);
                }
            }
            .change {
                display: flex;
                column-gap: 22px;
                align-items: center;
                .btn_l,.btn_r {
                    height: 44px;
                    img {
                        width: 44px;
                        height: 44px;
                        object-fit: cover;
                    }
                }
                .center {
                    font-size: 28px;
                    color: #666666;
                }
            }
        }
        .cooperate {
            margin: 30px 0;
        }
        p {
            text-indent: 2em;
            line-height: 42px;
        }
        .number {
            margin: 40px 0;
            display: flex;
            flex-direction: column;
            row-gap: 40px;
            div {
                display: flex;
                align-items: center;
            }
            span {
                color: #FFA71D;
                font-size: 36px;
                font-weight: bold;
            }
        }
        .img_list {
            width: 100%;
            height: 460px;
            display: flex;
            column-gap: 20px;
            overflow-x: scroll;
            box-sizing: border-box;
            &::-webkit-scrollbar {
                width: 0;
                background: transparent;
            }
            &_item {
                width: 660px;
                height: 420px;
                flex-shrink: 0;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }
    .intention {
        padding: 50px 30px;
        box-sizing: border-box;
        background: #F5F5F5;
        .subtitle {
            font-size: 28px;
            color: #666666;
        }
        .select_list {
            display: flex;
            column-gap: 24px;
            flex-wrap: wrap;
            row-gap: 24px;
            margin-top: 30px;
            &_item {
                flex-shrink: 0;
                color: #999999;
                font-size: 24px;
                padding: 8px 16px;
                box-sizing: border-box;
                border: 1px solid #EEEEEE;
                border-radius: 4px;
                background: #fff;
                transition: all 0.3s ease;
            }
            .active {
                background: #FFA71D;
                color: #fff;
            }
        }
        .form {
            display: flex;
            flex-direction: column;
            margin-top: 28px;
            row-gap: 20px;
            .tip {
                font-size: 24px;
                color: #FF5151;
                margin-bottom: 10px;
            }
            input,textarea {
                width: 100%;
                box-sizing: border-box;
                color: #999;
                outline:none;
                border: none;
                border-radius: 4px;
            }
            input {
                height: 72px;
                padding-left: 16px;
                padding-right: 16px;
                font-size: 28px;
            }
            textarea {
                padding: 16px;
            }
            .btn {
                height: 72px;
                background: #FFA71D;
                border-radius: 4px;
                color: #FFFFFF;
                font-weight: bold;
                display: flex;
                align-items: center;
                justify-content: center;
                margin: 10px 0 20px 0;
            }
            .phone {
                color: #666;
                font-size: 28px;
                text-align: center;
            }
        }
    }
}
</style>